













































import Product from "@/models/Product"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import { getModule } from "vuex-module-decorators"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import OrderDetail from "@/models/OrderDetail"
import SessionModule from "@/store/SessionModule"
import LangModule from "@/store/LangModule";

@Component
export default class UneditableProductListComponent extends Vue {


	@Prop() readonly orderDetail!: OrderDetail
	@Prop() readonly pvpPrices!: boolean
  @Prop() readonly rate!: number

	lang: any = getModule(LangModule).lang
	sessionModule: SessionModule = getModule(SessionModule)
	isDistributor = this.sessionModule.session.authorities.map(e => e.title == "distributor")[0]
	BASE_URL = ConstantTool.BASE_URL
  image: string = ""
	amount: number = 0

	created() { this.refresh() }

	errorHandler() {
    this.image = "../assets/ban.svg"
    this.$forceUpdate()
  }

  refresh() {
		console.log(this.orderDetail)
    this.image = `${this.BASE_URL}/storage/ftp/art_${this.orderDetail.product!.connectorId?.toLowerCase()}_1_tmb.jpg`
  }


	get price() { return Number(this.orderDetail.product!.rates![this.rate! - 1].price) }
	get pricePvp() { return Number(this.orderDetail.product!.commercialRate!) }
	get	total() { return (Number(this.orderDetail.product!.rates![this.rate! - 1].price) * (this.orderDetail!.quantity!)).toFixed(2) }
	get totalPvp() { return Number(this.orderDetail.product!.commercialRate! * this.orderDetail!.quantity!).toFixed(2) }

	addFavourite() {
    getModule(SnackbarModule).makeToast(`El producto ha sido marcado como favorito.`)
  }

  @Watch('product')
  onProductChanged() {
    this.refresh()
	}

}
